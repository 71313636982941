// Imports
// ------------
import React from 'react';
import styled, { css } from 'styled-components';
import WebglGlobe from '@parts/WebglGlobe';


// Styles
// ------------
const Jacket = styled.div(props => css`
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background: ${props.theme.colors.brand.bc2};
`)

// Component
// ------------
const Globe = () => {
    return (
        <Jacket>
			<WebglGlobe centered={true}/>

        </Jacket>
    );
}

export default Globe;
